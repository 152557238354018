import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// js
import Layout from "../components/Layout"
import ArticleList from "../components/ArticleList"
import ArticleList2 from "../components/ArticleList2"
import SEO from "../components/SEO"

// css
import styles from "../css/category.module.css"

// plugins
import AniLink from "gatsby-plugin-transition-link/AniLink"

const getData = graphql`
  query {
    mainArticles: allContentfulArticle(
      limit: 5
      sort: { fields: dateDePublication, order: DESC }
      filter: { categorie: { eq: "Film" } }
    ) {
      edges {
        node {
          titre
          dateDePublication(formatString: "DD/MM/YYYY")
          presentation {
            presentation
          }
          photoPrincipale {
            fluid {
              src
            }
          }
          slug
          auteur {
            nom
            slug
            description {
              description
            }
            photo {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
    otherArticles: allContentfulArticle(
      skip: 5
      sort: { fields: dateDePublication, order: DESC }
      filter: { categorie: { eq: "Film" } }
    ) {
      edges {
        node {
          titre
          dateDePublication(formatString: "DD/MM/YYYY")
          presentation {
            presentation
          }
          photoPrincipale {
            fluid {
              src
            }
          }
          slug
          auteur {
            nom
            slug
            description {
              description
            }
            photo {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  }
`

const Film = () => {
  const { mainArticles, otherArticles, presentation } = useStaticQuery(getData)

  const url = typeof window !== "undefined" ? window.location.href : ""

  return (
    <Layout>
      <SEO title="Film" description="catégorie Film de Sudfa Média" url={url} />
      <div className={styles.container}>
        <h1>Film documentaire: "Giddam ! - Jusqu'au bout !"</h1>
        <div className={styles.line}></div>

        <ArticleList articles={mainArticles.edges} />
        {otherArticles.edges.length > 0 && (
          <ArticleList2 data={otherArticles} slug="/film/" />
        )}
      </div>
    </Layout>
  )
}

export default Film
